<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #head(id)>
            <b-form-checkbox
              :id="'checkbox-staff-all'"
              :name="'checkbox-staff--all'"
              :value="true"
              :unchecked-value="false"
              @change="$emit('handleCheckAll', $event)"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(id)="{ item }">
            <b-form-checkbox
              :id="'checkbox-staff-' + item.id"
              v-model="item.isChecked"
              :name="'checkbox-staff-' + item.id"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(first_name)="{ item }">
            <span>{{ stringName(item) }} </span>
          </template>
          <template v-slot:cell(total_price)="{ item }">
            <span>{{ item.total_price.toLocaleString() }} </span>
          </template>
          <template v-slot:cell(available)="{ item }">
            <span>{{ item.available.toLocaleString() }} </span>
          </template>
     
          <template v-slot:cell(active)="{ item }">
            <span :class="item.active ? 'text-success' : 'text-error'">{{
              item.active ? "Active" : "Inactive"
            }}</span>
          </template>
          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-evenly">
              <router-link
                :to="
                  'manageBranch/inventory/' +
                  item.id +
                  `?${encodeParam(`name=${item.name}`)}`
                "
              >
                <b-button variant="link" class="text-bg-primary px-1 py-0">
                  <font-awesome-icon icon="warehouse" title="Inventory" />
                </b-button>
              </router-link>
              <router-link :to="'manageBranch/detail/' + item.id">
                <b-button variant="link" class="text-warning px-1 py-0">
                  <font-awesome-icon icon="pencil-alt" title="Edit" />
                </b-button>
              </router-link>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  methods: {
    encodeParam(url) {
      return encodeURI(url);
    },
    toggleCheckAll(val) {
      this.items.map((el) => (el.isCheck = val));
    },
    stringName(item) {
      const first_name = item.first_name || "";
      const last_name = item.last_name || "";

      return first_name + " " + last_name;
    },
    handleChangeTake(value) {
      this.filter.page = 1;

      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}
</style>
